<template>
    <div class="no-insurance">
        <h2 class="no-insurance-header">
            Reduced Self-Pay Option
        </h2>
        <p class="no-insurance-copy">
            All patients are eligible for the reduced self-pay option of $330. Self-Pay may be the most affordable option for patients whose out-of-pocket cost is estimated to be higher than $330, for those whose insurance does not cover the GeneSight test, or for those without insurance.
        </p>
        <p class="no-insurance-copy">
            We understand that $330 is still a significant expense for many patients, so we offer a variety of payment options, such as financial assistance for those who qualify and an interest-free payment plan. Any amount you do pay is FSA/HSA eligible. To learn more about these options, please contact our Customer Service team directly at <a class="link no-insurance-link no-decoration" href="mailto:support@genesight.com">support@genesight.com</a> or <a class="link no-insurance-link no-decoration" href="tel:8667579204">866.757.9204</a>.
        </p>
    </div>  
</template>

<script>
export default {
    methods: {
        returnToInsurancePage() {
            this.$emit("close-no-insurance");
        }
    }
};
</script>

<style lang="scss" scoped>
    .no-insurance {
        min-height: 385px;
    }

    .no-insurance-header {
        margin-top: 60px;
        font-size: $no-insurance-header-font-size;
    }

    .no-insurance-copy {
        margin-top: 15px;
        font-size: $regular-copy-size;
        max-width: 860px;
    }

    .no-insurance-contact {
        margin-top: 15px;
        margin-bottom: 150px;
    }

    .no-insurance-cs-header {
        font-size: $regular-copy-size;
    }

    .no-insurace-cs-spacing {
        margin-bottom: 7px;
    }

    .no-insurance-link {
        font-size: $btn-font-size;
        display: inline-block;
    }
    .no-decoration {
        text-decoration: none;
    }
</style>
